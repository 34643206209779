<template>
	<div id="flowPath" v-if="menus[1]" v-html="menus[1].menuText">
		<!-- 参与流程 -->
	</div>
</template>

<script>
	import {
		ProjectPage // 优秀稿件
	} from '@/request/api';
	export default {
		name: 'flowPath',
		data() {
			return {
				menus: ''
			}
		},
		created() {
			this.getProjectPage();
		},
		mounted() {},
		methods: {
			getProjectPage() {
				ProjectPage().then(res => {
					if (res.code == 200) {
						this.menus = res.data.menus
					}
				}).catch(err => {
					console.log(err)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#flowPath {
		text-align: justify;

		::v-deep img {
			display: block;
			margin: auto;
			width: 100%;
		}
	}
</style>